//sidebar

.sidebar {
  top: 55px;
  width: 200px;
  float: left;
  height: calc(100vh - 0rem);
  z-index: 1;
  border-right: 2px solid #ccc;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-duration: 0.5s;
 

  &.active {
    .sidebar-toggle {
      margin-right: 20px;
    }

    width: 45px;

    .hoverSidebar {
      .nav-item {
        .nav-link {
          padding: 0.5rem 0.5rem;

          i {
            font-size: 18px !important;
            padding-right: 5px !important;
          }

          span {
            display: none;
          }
        }
      }
    }

    &:hover {
      width: 210px;

      .hoverSidebar.active {
        .nav-item {
          .nav-link {
            padding: 0.5rem 0.5rem;

            i {
              font-size: 18px !important;
              padding-right: 5px !important;
            }

            span {
              display: unset;
            }
          }
        }
      }
    }
  }

  .navbar {
    background: linear-gradient(to bottom,#f9f9f9,#f2f2f2);
    width: 100%;
    height: calc(90vh - 0rem);
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      background: linear-gradient(to right,#f9f9f9,#f2f2f2);
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      transition: all 0.3s ease;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #3b3b3b;
      }
    }

    .nav-item {
      border-bottom: 1px solid #DFDFDF;
      >.nav-link {
        padding: 0.5rem 0.5rem;
        white-space: nowrap;
        color: $dark;
        font-size: $font-size-sm;
        line-height: 0;

        i {
          min-width: 34px;
          font-size: 18px;
          padding-right: 5px;
          @include transition($btn-transition);
        }

        &.active {
          color: #4D8BA5;
          font-weight: $font-weight-bold;
          background-color: rgba(0, 0, 0, 0.05);
          i {
            color: #4D8BA5;
          }
        }

        &:hover {
          background: rgba(0, 0, 0, .05);
        }
      }
    }
    .dropdown {
      .ts-dropdown-menu {
        .nav-item {
         font-size: 10pt;
         &:hover {
          background-color: rgba(0, 0, 0, 0.05);
         }
        }
      }
    }
  }

  .side-dropdown-toggle.collapsed {
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: "\f0fe";
      font-family: 'Font Awesome 5 Free';
      position: absolute;
      right: 0;
      margin-right: 5px;
      transition: all 0.3s ease;
      font-size: 11px;
    }
  }

  .side-dropdown-toggle {
    background: #f6f6f6!important;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: "\f146";
      font-family: 'Font Awesome 5 Free';
      position: absolute;
      right: 0;
      margin-right: 5px;
      transition: all 0.3s ease;
      font-size: 11px;
    }
  }

}