.header {
    z-index: 100;
    box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
    .navbar {
        margin-bottom: 0;
        // background-image: linear-gradient(45deg, $theme, $dark);

        .nav-brand {
            h1 {
                font-size: 2.2rem;
                color: $white;
            }
        }

        .nav {
            .nav-item {
                .nav-link {
                    &.active {
                        color: $primary;
                    }

                    .user-menu {
                        .user-menu-title {
                            line-height: 5px;

                            h4 {
                                font-size: 14px;
                            }

                            small {
                                font-size: 10px;
                                font-weight: bold;
                                color: #a3ab67;
                            }
                        }
                    }
                }
            }

            &.top-header-main-menu {
                .nav-item {
                    .nav-link {
                        font-size: $font-size-sm;
                        color: $light;
                    }
                }
            }

            &.top-header-icons-menu {
                .nav-item {
                    &>.nav-link {
                        font-size: $font-size-sm;
                        color: $light;

                        span {
                            font-size: 17px;
                            padding-right: 5px;
                        }
                    }
                }

                .dropdown-menu {
                    .nav-item {
                        .nav-link {
                            color: $licoriceB;
                            font-size: $font-size-sm;
                            @include transition($btn-transition);

                            &:hover {
                                color: $white;
                                background: $gray-600;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        .navbar {
            height: 55px;
        }
    }
    .sidebar-toggle {
        margin-right: 10px;
        padding: 1rem 0.5rem;
        float: left;
    
        .burger {
          div {
            width: 20px;
            height: 3px;
            background-color: $white;
            margin: 3px;
            transition: all 0.3s ease;
          }
    
          &.active {
            .line1 {
              transform: rotate(-45deg) translate(-4px, 5px);
            }
    
            .line2 {
              opacity: 0;
            }
    
            .line3 {
              transform: rotate(45deg) translate(-3px, -4px);
            }
          }
        }
      }
}

.fa-layers {
    display: inline-block;
    height: 1em;
    position: relative;
    text-align: center;
    vertical-align: -.125em;
    width: 1em;

    .fa-layers-counter {
        background-color: #ff253a;
        border-radius: 1em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: $white;
        height: 1.5em;
        line-height: 1;
        max-width: 5em;
        min-width: 1.5em;
        overflow: hidden;
        padding: 0.25em;
        right: -22px;
        text-overflow: ellipsis;
        top: 0;
        transform: scale(0.59);
        transform-origin: top right;
        display: inline-block;
        position: absolute;
        text-align: center;
    }

    .fa-layers-text {
        display: inline-block;
        position: absolute;
        text-align: center;
    }
}