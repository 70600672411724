.dropdown {
    position: relative;
    .dropdown-menu {
      transition: all 0.9s ease;
    }
    &.show {
      .dropdown-menu {
        width: 100%;
      }
    }
  }