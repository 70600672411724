@import 'admin/navigation';
@import 'admin/sidebar';
@import 'admin/dropdown';
@import 'admin/dashboard';
@import 'admin/footer';

body {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 10px;
        background: #3b3b3b;
        // background: linear-gradient(to bottom,#f9f9f9,#f2f2f2);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ccc;
        // background: linear-gradient(to bottom,#f9f9f9,#f2f2f2);
        // background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#04ffb4), to(#2196fd), color-stop(.6, #2196fd));
      }
}
.table[border="1"] {
    border-color: #ccc!important;
}
.card.border-top-custom {
    border-top: 3px solid $danger;
}

.btn-table {
    padding: 0.15rem 0.25rem!important;
    font-size: 12px;
}
.icon-table {
    font-size: 11px!important;
    padding-right: 2px;
}

/*search*/

.has-search {
    position: relative;
    display: flex;
    align-items: center;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
 -webkit-appearance: none;
 margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
.content {
    min-height: 100vh;
}
.colon{
    text-align: center;
    width: 5%;
}
.table-title{
    width: 40%;
}
