$color1: $primary;
$color2: $info;
$color3: $success;
$color4: $warning;
$color5: #143965;
$color6: #FE4952;
$color7: #4b89a8;
$color8: #7E85DB;
$color9: #7C83DA;
$color10: #CE1783;
$color11: #948F89;
$color12: #623134;
$color13: #D41E89;
$color14: #2F2A79;
$color15: #79232C;
$color16: #1C3531;

//dashboard
.main {
    position: relative;
    top: 55px;
    width: 100%;
    min-height: 100vh;
    padding-left: calc(200px - 0rem);
    float: left;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration: 0.5s;
    &.active {
        padding-left: calc(45px - 0rem);
    }
    .sidebar-toggle {
        float: left;
        margin-right: 10px;
        padding: 0.2rem 0.5rem;
    }
}

.dashboard-card {
    .card {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
        &:hover {
            opacity: 0.8;
        }
        h5 {
            font-size: 14px;
            font-weight: bold;
        }
    }
    .bg-color1 {
        background-color: $color1;
    }
    .bg-color2 {
        background-color: $color2;
    }
    .bg-color3 {
        background-color: $color3;
    }
    .bg-color4 {
        background-color: $color4;
    }
    .bg-color5 {
        background-color: $color5;
    }
    .bg-color6 {
        background-color: $color6;
    }
    .bg-color7 {
        background-color: $color7;
    }
    .bg-color8 {
        background-color: $color8;
    }
    .bg-color9 {
        background-color: $color9;
    }
    .bg-color10 {
        background-color: $color10;
    }
    .bg-color11 {
        background-color: $color11;
    }
    .bg-color12 {
        background-color: $color12;
    }
    .bg-color13 {
        background-color: $color13;
    }
    .bg-color14 {
        background-color: $color14;
    }
    .bg-color15 {
        background-color: $color15;
    }
    .bg-color16 {
        background-color: $color16;
    }
}
  